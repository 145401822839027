import React from 'react';

export const DaNang = {
    "name": "Da Nang",
    "link": "da-nang",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380546772_7f26d0faf7_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380546772_a715a7026d_o.jpg",
    "caption": "The bridge in the sky",

    "description": "Da Nang is a delightful coastal city in Vietnam that once was premiered with the prestigious Asian Townscape Award. It not only holds one of the world’s most beautiful beaches (My Khe Beach), but offers as well a modern city with a deep history and is surrounded by mountains, rivers, waterfalls, and forests. Once seen by travelers as a mere gateway to Hoi An, this laid-back Central Vietnamese city of one million is now demanding time for itself.",

    "nrDays": <p>Spending <b>two days</b> in Da Nang is enough to see the major points of the city. Of course, if you want to make day trips with Da Nang being the anchoring spot you will need additional days for that.
              </p>,

    "whenVisit": <p>There are two good seasons to visit the city. One of them is from <b>May to August</b>. You can stretch on the white sandy beach and immerse yourself in My Khe Beach. The other one is from <b>February to April</b>, and it's the one we recommend, when the weather is not too warm yet, but good enough to go to the beach and amount of tourists is not too huge.
                 </p>,

    "mobility": <p>
                The hot spots of the city are not close to each other, and the idea of getting all the way on foot is difficult here (a little impossible considering if you want to go to the Ba Na Hills). However, as in other Vietnam cities you have some good ways to move there. One of them is renting a scooter. It will cost you 100000VND (around 3$) a day. It is the best way if you want full freedom in your timing. The other solution is to use Grab. For the Ba Na Hills, you will need to catch the cable car, which will take around 15 minutes.
                <br/><br/>
                To get to Da Nang you have some solutions. The airport is near to city and is the best option if you are short on time. However, we hugely recommend you take the Hai Van Pass if you come from the North! If you are doing the opposite path, leave the city by the Hai Van Pass instead. There is a reason why it is called the <b>must do route</b> for bikes in Vietnam, and you will see amazing landscapes. The train is also a good option for those afraid to drive on a motorbike..
                </p>,

    "safety": <p>Da Nang is a very safe city to travel. Probably, the biggest issue that you will find here is the bribery. The roads are less chaotic, and the traffic here is lower than in other parts of the country and the street crime is rare. Though, be always careful with your personal belongings, with special attention if you are at the Marble Mountains.
            </p>,

    "itinerary": {
        "description": "As suggested before, we recommend at least 2 days to see the city. It is enough time to see some of the hidden places and to embrace the beauty and the atmosphere of the city. For that we will suggest a 2-day itinerary where you can visit all the hot spots. Of course, it’s only a suggestion and it is possible to adjust as you wish.",
        "days": [
            {
                "description":
                    <p>
                        On the first day in the city you can start with a visit to the Ba Na Hills! Located about 30km from the centre of the city, Ba Na Hills is an eco-resort combining relaxing and entertaining areas with modern equipment. The best time to visit the park is from 9am to 5pm. Here you can also find the “Hands of a God”, that despite finished construction only in 2017, have become a symbol of the place and city. For some downtime after the Sun World hubbub, laze at Holiday Beach Club, one of the most inviting beach spots. At night get a spot to watch the Golden Bridge. If you are in the city during the weekend you can attend the Fire & Water Performance.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50504996111_f927b07acd_o.jpg",
                "caption": "The Hands of a God."
            },
            {
                "description": <p>
                For the second day we suggest starting the day with a visit to My Khe Beach. After a good lunch at Bánh Tráng Cuốn Thịt Heo (we recommend the Ricepaper Rolls with Sliced Pork), you can save your afternoon to visit the Marble Mountains. To finish your day, you can spend some time at Hàn Market and have a coffee at Công Cafe.
                </p>,
                "image": "https://live.staticflickr.com/65535/50504281613_a991f780b3_o.jpg",
                "caption": "Sunset on My Khe Beach."
            }
        ]
    },

    "highlights": [
        {
            "name": "Ba Na Hills",
            "description": "This spot is the reason why many people to travel to Da Nang. Ba Na is a perfect tourist destination with four separate seasons in a day: spring in the morning, summer at noon, autumn starts in the afternoon, and the winter chill begins in the evening. On the way to Ba Na Hills, you will have the opportunity to discover a fantastic scene of mountains and experience the highest and longest cable car in the world. Here you will have the chance to see one of the most instagramable spots nowadays, the Golden Brigde, “supported by the Hands of a God”. If you want to have the place for yourself you should arrive very soon (it opens at 7am). The entrance fee is up to 24$.",
            "image": "https://live.staticflickr.com/65535/50504281583_4d41bbc75c_o.jpg"
        },
        {
            "name": "My Khe Beach",
            "description": "This place was voted by Forbes magazine as one of the most attractive beaches on the world. It is an ideal and huge beach with smooth sand and a slight slope. There is also a great collection of water sport activities, making your trip to the beach even more memorable and fun.",
            "image": "https://live.staticflickr.com/65535/50505155067_a606969bc4_o.jpg"
        },
        {
            "name": "The Dragon Bridge",
            "description": "The Dragon Bridge is one of the most famous symbols of he city of Da Nang. It is the longest bridge in Vietnam, took 3 years to construct and was only opened to traffic in 2013. The dragon is a symbol of power, nobility and good fortune. Don’t forget to visit and see the amazing performance of the bridge, when the dragon built there can breathe fire for 2 minutes, and proceed with 3 minutes of water breathing. The Fire and Water Performance is an incredible highlight of the city which happens at 9.pm every weekend and holidays.",
            "image": "https://live.staticflickr.com/65535/50504281538_1dfd0c0911_o.jpg"
        },
        {
            "name": "The Han Market",
            "description": "The Han Market is located at the grand intersection of Bach Dang Street, Tran Phu Street, Tran Hung Dao Street, and Hung Vuong Street. The market holds a vital position in the history of the city and is popular among tourists and locals alike. This is the ideal place to get unique souvenirs.",
            "image": "https://live.staticflickr.com/65535/50505155062_2f207beaf0_o.jpg"
        },
        {
            "name": "Marble Mountains",
            "description": "Marble Mountains or \"five elements mountains\" is a cluster of five marble and limestone hills located in Ngũ Hành Sơn District, south of Da Nang. All the mountains have cave entrances and several tunnels, and it is possible to rise to the summit of Mount Thuy. Quite a few Buddhist sanctuaries can be found within the mountains, making up this tourist destination.",
            "image": "https://live.staticflickr.com/65535/50504281523_d3951bd228_o.jpg"
        }
    ],

    "food": {
        "description": "",
        "restaurants": [
            {
                "name": "Rom Kitchen",
                "description": "With nice staff and very economical options, you will be satisfied with the diverse food served here.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504995961_1a99a22a51_o.jpg"
            },
            {
                "name": "Thia Go Danang-style restaurant",
                "description": "This restaurant is a very economical option. Also has nice staff and fast service with a friendly environment.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504281463_1f1f395bfc_o.jpg"
            },
            {
                "name": "Lang Nghe Restaurant",
                "description": "Comfortable, airy restaurant with a very friendly, attentive staff. There’s so much to choose from you need a good 15 minutes to peruse the whole menu.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50504995891_04ae852208_o.jpg"
            },
            {
                "name": "Bep Cuon Da Nang",
                "description": "One of best restaurants in Da Nang with friendly services. We strongly recommend you try Goi Cuon and Pork on the Sticks (Thit Xo Lui).",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50505154957_b4fa2d9145_o.jpg"
            },
            {
                "name": "Sky View Restaurant",
                "description": "This is an amazing restaurant with a sea view. You should try the sea food here.",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50504995861_5b24cfe441_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Since Da Nang is a common destination among tourists of all kinds, you shouldn’t have any problem to find a place to stay that works for you. It is always important to consider what part of the city you should look. A central place, close to attractions, restaurants and other facilities are aspects that you need to take into account, even the major spots in Da Nang are a little dislocated.",
        "budget": [
            {
                "name": "An Macrobiotic Homestay & Apartment",
                "description": "Close to My Khe Beach, this accommodation is a nice option for groups, with shared a restaurant and living rooms. It's a very economic option.",
                "image": "https://live.staticflickr.com/65535/50504281408_04628ce1a6_o.jpg",
                "link": "/"
            },
            {
                "name": "Cherry Homestay",
                "description": "Close to the previous one, Cherry Homestay is also a very cheap option. However, it has not the same conditions of the previous one.",
                "image": "https://live.staticflickr.com/65535/50504281388_9492d43423_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Sofia Boutique Hotel",
                "description": "A little dislocated of the city centre, but closer to the airport. This is a good choice if you have easy mobility.",
                "image": "https://live.staticflickr.com/65535/50504995826_e33c863e66_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Prana Boutique Hotel and Apartments",
                "description": "If you are searching for a luxurious spot to stay, you will not be disappointed with this one in particular. It is close to the beach, with large rooms, and a swimming pool on the rooftop.",
                "image": "https://live.staticflickr.com/65535/50504995821_eaf21bc4ae_o.jpg",
                "link": "/"
            }
        ]
    }
}
